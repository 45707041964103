// Colors
$dark-grey: #333;
$grey: #666;
$blue: #1c88bf;
$dark-blue: #364051;
$off-white: #f7f7f7;
// Font Size
$font-size-lg: 1.8rem;
$font-size-md: 1.5rem;
// Spacing
$s-size: 1.1rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
